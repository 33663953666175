import React, { createContext, useState } from "react";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";

export const AuthContext = createContext(null);

export const AuthProvider = ({ isLoggedIn: isLoggedInProp, children }) => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(isLoggedInProp);

    const userLogin = async (token, id, email) => {
        try {
            localStorage.setItem("did_monitor_token", token);
            localStorage.setItem("did_monitor_email", email);
            setIsLoggedIn(true);

            setTimeout(() => {
                window.location.reload();
            }, 600);
        } catch (e) {
            toast.error(e.message);
        }
    };

    const userLogout = async () => {
        try {
            localStorage.removeItem("did_monitor_token");
            localStorage.removeItem("did_monitor_email");
            setIsLoggedIn(false);
            // navigate("/");
            setTimeout(() => {
                window.location.reload();
            }, 600);
        } catch (e) {
            toast.error(e.message);
        } finally {
            localStorage.clear();
        }
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, userLogin, userLogout }}>
            {children}
        </AuthContext.Provider>
    );
};
