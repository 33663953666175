import React, {useCallback, useContext} from "react";
import styled from "styled-components";
import theme from "../../styles/theme";
import {MainContext} from "../../pages/Main";
import {colorChange, maskingName} from "../../utils/commons";

const NumberText = styled.div`
  width: ${({$width}) => $width}px;
  font-size: ${({$fontSize}) => $fontSize}px;
  font-weight: 500;
  color: ${({$fontColor}) => $fontColor ? $fontColor : theme.colors.blackColor};
  margin-right: 6px;
`;
const Text = styled.div`
  width: ${({$width}) => $width}%;
  font-size: ${({$fontSize}) => $fontSize}px;
  font-weight: 500;
  color: ${({$fontColor}) => $fontColor ? $fontColor : theme.colors.blackColor};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const PatientInfoText = ({
                             NUMBER,
                             index,
                             type,
                             width,
                             list,
                             fontSize,
                             numberWidth,
                         }) => {
    const {didData} = useContext(MainContext);
    const getFontColor = useCallback(data => {
        if (data?.WaitingStatus === '보류중' && didData.did_holdingColorUsed) {
            return colorChange(didData.did_holdingColor);
        } else if (data.Memo.includes('응급') && didData.did_erColorUsed) {
            return colorChange(didData.did_erColor);
        } else if (data.WaitingStatus === '진료중') {
            return theme.colors.activeCoral;
        }
    }, [didData]);

    if (type === 'empty') return;

    return (
        <>
            {NUMBER && (
                <NumberText $width={numberWidth} $fontSize={fontSize} $fontColor={getFontColor(list)}>
                    {index}.&nbsp;
                </NumberText>
            )}
            {type === 'patName' && (
                <Text $width={width} $fontSize={fontSize} $fontColor={getFontColor(list)}>
                    {didData?.did_nameMasking ? maskingName(list.PatientName || '') : list.PatientName}
                </Text>
            )}
            {type === 'doctorRoom' && (
                <Text $width={width} $fontSize={fontSize} $fontColor={getFontColor(list)}>
                    {list.DeptName}
                </Text>
            )}
            {type === 'gender' && (
                <Text $width={width} $fontSize={fontSize} $fontColor={getFontColor(list)}>
                    {list.Gender}
                </Text>
            )}
            {type === 'time' && (
                <Text $width={width} $fontSize={fontSize} $fontColor={getFontColor(list)}>
                    {list.ReceptionTime}
                </Text>
            )}
            {type === 'doctor' && (
                <Text $width={width} $fontSize={fontSize} $fontColor={getFontColor(list)}>
                    {list.DoctorName}
                </Text>
            )}
            {type === 'status' && (
                <Text $width={width} $fontSize={fontSize} $fontColor={getFontColor(list)}>
                    {list.WaitingStatus}
                </Text>
            )}
            {type === 'chartNumber' && (
                <Text $width={width} $fontSize={fontSize} $fontColor={getFontColor(list)}>
                    {list.ChartNo}
                </Text>
            )}
            {type === 'birthday' && (
                <Text $width={width} $fontSize={fontSize} $fontColor={getFontColor(list)}>
                    {list.Birth}
                </Text>
            )}
            {type === 'age' && (
                <Text $width={width} $fontSize={fontSize} $fontColor={getFontColor(list)}>
                    {list.Age}
                </Text>
            )}
            {type === 'memo' && (
                <Text $width={width} $fontSize={fontSize} $fontColor={getFontColor(list)}>
                    {list.Memo}
                </Text>
            )}
        </>
    )
}

export default PatientInfoText;
