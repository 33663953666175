import { gql } from '@apollo/client';

export const DID_LOGIN = gql`
    mutation DidLogin(
        $accountId: String!
        $password: String!
        $didUniqueId: String!
    ) {
        didLogin(
            accountId: $accountId
            password: $password
            did_uniqueId: $didUniqueId
        ) {
            did_id
            email
            token
        }
    }
`;
