import styled, {css} from "styled-components";
import theme from "../../styles/theme";

export const Wrapper = styled.div`
  width: 100vw;
  height: ${({ $height }) => $height};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${theme.colors.blackColor};
  
  ${({$down}) => $down && css`
    flex-direction: column-reverse;
  `};
  ${({$left}) => $left && css`
    flex-direction: row;
  `};
  ${({$right}) => $right && css`
    flex-direction: row-reverse;
  `};
`;
export const FullScreen = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 1;

  ${({ $isMedia }) => $isMedia && css`
    z-index: -1;
    opacity: 0;
  `};
`;
export const BlackScreen = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: ${theme.colors.blackColor};
`;
export const LogoutArea = styled.div`
  width: 100%;
  height: 46px;
  position: absolute;
  top: 0;
  z-index: 100;
  background-color: inherit;
`;
export const UserLogout = styled.button`
  height: 46px;
  font-size: 22px;
  font-weight: bold;
  padding: 12px 22px 10px;
  border-radius: 4px;
  position: absolute;
  transition: .25s;
  top: -48px;
  left: 0;
  z-index: 100;
  
  ${LogoutArea}:hover & {
    top: 0;
  }
`;
