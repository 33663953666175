import {useState, useEffect} from "react";
import "react-toastify/dist/ReactToastify.css";
import 'swiper/css';
import GlobalStyles from "./styles/GlobalStyles";
import {toast, ToastContainer} from "react-toastify";
import AppRouter from "./Router";
import {ThemeProvider} from "styled-components";
import theme from "./styles/theme";
import Test from "./Test";

// if (process.env.NODE_ENV === 'production') {
//     console.log = function no_console() {}
//     console.warn = function no_console() {}
//     console.info = function no_console() {}
// }

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(null);

    const previousLoading = () => {
        try {
            // const info = localStorage.getItem("did_monitor_info");
            const token = localStorage.getItem(`did_monitor_token`);

            if (token) {
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
        } catch (e) {
            toast.error(e);
        }
    };

    useEffect(() => {
        previousLoading();
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles/>
            <ToastContainer  position={toast.POSITION.TOP_RIGHT}/>
            <AppRouter isLoggedIn={isLoggedIn} />
            {/*<Test />*/}
        </ThemeProvider>
    );
}

export default App;
