import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from '../../styles/theme';
import { IoEyeSharp, IoEyeOffSharp } from 'react-icons/io5';

const Wrapper = styled.div`
    width: ${({ $width }) => $width}px;
    height: ${({ $height }) => $height}px;
    margin: ${({ $margin }) => ($margin ? $margin : 0)};
    display: flex;
    align-items: center;
    padding-right: 12px;
    justify-content: space-between;
    border: ${({ $border }) => $border};
    border-radius: 4px;
`;
const Input = styled.input`
    width: 90%;
    height: 100%;
    padding: 0 16px;
`;
const ViewButton = styled.div`
    cursor: pointer;
`;

const StyledPasswordInput = ({
    width = 440,
    height = 50,
    margin,
    border = `1px solid ${theme.colors.lightGrayBorder}`,
    name,
    value,
    onChange,
    onKeyUp,
    maxLength = 200,
    disabled = false,
    placeholder
}) => {
    const [visible, setVisible] = useState(false);

    return (
        <Wrapper $width={width} $height={height} $margin={margin} $border={border}>
            <Input
                name={name}
                value={value}
                type={visible ? 'text' : 'password'}
                onChange={onChange}
                onKeyUp={onKeyUp}
                maxLength={maxLength}
                disabled={disabled}
                placeholder={placeholder}
                autoComplete="off"
            />
            <ViewButton onClick={() => setVisible(!visible)}>
                {visible ? (
                    <IoEyeOffSharp fontSize={18} color={theme.colors.fontGrayColor} />
                ) : (
                    <IoEyeSharp fontSize={18} color={theme.colors.fontGrayColor} />
                )}
            </ViewButton>
        </Wrapper>
    );
};

export default React.memo(StyledPasswordInput);
