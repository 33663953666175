import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import AppleSDGothicNeoBold from './fonts/AppleSDGothicNeoB.ttf';
import AppleSDGothicNeoMedium from './fonts/AppleSDGothicNeoM.ttf';
import AppleSDGothicNeoRegular from './fonts/AppleSDGothicNeoR.ttf';
import AppleSDGothicNeoLight from './fonts/AppleSDGothicNeoL.ttf';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';

export default createGlobalStyle`
  ${reset};

  @font-face {
    font-family: 'AppleSDGothicNeoBold';
    src: url(${AppleSDGothicNeoBold});
  }

  @font-face {
    font-family: 'AppleSDGothicNeoMedium';
    src: url(${AppleSDGothicNeoMedium});
  }

  @font-face {
    font-family: 'AppleSDGothicNeoRegular';
    src: url(${AppleSDGothicNeoRegular});
  }

  @font-face {
    font-family: 'AppleSDGothicNeoLight';
    src: url(${AppleSDGothicNeoLight});
  }

  * {
    box-sizing: border-box;
    white-space: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
  }

  body {
    width: 100%;
    font-size: 16px;
    font-family: 'AppleSDGothicNeoMedium', 'SF Pro Display', sans-serif;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    background-color: ${({ theme }) => theme.colors.whiteColor};

    body::-webkit-scrollbar {
      display: none;
    }
  }

  a {
    color: ${({ theme }) => theme.colors.blackColor};
    text-decoration: none;
  }

  textarea {
    resize: none;
  }

  input,
  textarea {
    font-size: 16px;
    font-family: 'AppleSDGothicNeoMedium';
    border: none;
    outline: none;

    &::placeholder {
      font-size: 16px;
      font-weight: 400;
      font-family: 'AppleSDGothicNeoRegular', 'SF Pro Display', sans-serif;
      color: ${({ theme }) => theme.colors.fontGrayColor};
    }
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }

  button {
    font-size: 16px;
    padding: 0;
    cursor: pointer;
    outline: none;
    border: none;
    background-color: ${({ theme }) => theme.colors.whiteColor};
  }

  select {
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
  }

  .Toastify__toast-container {
      width: 20%;
      min-width: 300px;
      max-width: 450px;
  }
`;
