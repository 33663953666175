import styled, {keyframes} from "styled-components";
import theme from "../../styles/theme";
import {BoldText, MediumText} from "../../components/styled/StyledText";

const Spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Wrapper = styled.div`
    min-width: 1320px;
    width: 100vw;
    //max-width: 1920px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;
export const LogoBox = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 6px;
    border: 1px solid ${theme.colors.lightGrayBorder};
    position: absolute;
    top: 42px;
    left: 42px;
    overflow: hidden;
`;
export const Image = styled.img`
    width: 100%;
    height: 100%;
`;
export const LoginForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
export const LoginTitle = styled(BoldText)`
    font-size: 32px;
`;
export const LoginSubTitle = styled(MediumText)`
    margin: 34px 0 68px;
`;
export const LoginButton = styled.button`
    width: 340px;
    height: 50px;
    font-family: 'AppleSDGothicNeoBold';
    color: ${theme.colors.whiteColor};
    margin-bottom: 15px;
    border-radius: 4px;
    background-color: ${theme.colors.activeIndigo};
`;
export const LoaderBox = styled.div`
    width: 100%;
    height: ${({ height }) => (height ? height : '100%')};
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const Loader = styled.div`
    width: 26px;
    height: 26px;
    border: 3px solid ${theme.colors.whiteColor};
    border-top: 3px solid ${theme.colors.activeBlue}; 
    border-radius: 50%;
    animation: ${Spin} 1.3s linear infinite;
`;
