import styled, {css} from "styled-components";
import theme from "../../styles/theme";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  // background-color: ${theme.colors.blackColor};
  
  ${({ $flex }) => $flex && css`
    flex: ${$flex};
  `};
`;
export const InfoBox = styled.div`
  width: 100%;
  height: 100%;
  flex: ${({flex}) => flex};
`;
export const TemporaryInfoBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  transition: .3s;
  transform: translateX(-100%);

  ${({isVideo}) => !isVideo && css`
    transform: translateX(0);
  `};
`;
export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  z-index: 0;
  opacity: 0;
  

  ${({$visible}) => $visible && css`
    z-index: 1;
    opacity: 1;
  `};
`;
export const PlayButtonBox = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);
`;
export const PlayButton = styled.button`
  width: 178px;
  height: 178px;
  font-size: 72px;
  color: ${theme.colors.whiteColor};
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  border: 6px solid ${theme.colors.whiteColor};
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.6);
`;
export const PlayInfoText = styled.div`
  font-size: 40px;
  font-family: 'AppleSDGothicNeoRegular';
  line-height: 1.6;
  text-align: center;
  color: ${theme.colors.whiteColor};
  margin-top: 40px;
`;
