import styled from "styled-components";
import theme from "../../styles/theme";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: ${({ $padding }) => $padding};
  flex: ${({$flex}) => $flex};
  background-color: ${theme.colors.infoBgColor};
`;
export const Contents = styled.div`
  width: 100%;
  height: ${({ $height }) => $height};
  padding: 30px;
  display: flex;
  gap: 30px;
  flex-direction: ${({ $flexDirection }) => $flexDirection};
  position: relative;
  margin-top: 50px;
  background-color: ${theme.colors.whiteColor};
`;
export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const LogoBox = styled.div`
  flex: 1;
`;
export const Image = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 6px;
  object-fit: contain;
`;
export const Title = styled.div`
  height: 100%;
  font-size: ${({ $fontSize }) => $fontSize ? $fontSize : 42}px;
  font-weight: 700;
  line-height: 1.2;
  word-break: keep-all;
  white-space: pre-wrap;
  text-align: right;
  color: ${theme.colors.whiteColor};
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex: 2;
  margin-top: ${({$marginBottom}) => $marginBottom ? $marginBottom : 0}px;
  margin-left: 10px;
`;
export const FlexBox = styled.div`
  flex: ${({ flex }) => flex ? flex : 2};
  display: flex;
  flex-direction: ${({$flexDirection}) => $flexDirection ? $flexDirection : "row"};
  align-items: ${({$alignItems}) => $alignItems ? $alignItems : "center"};
  justify-content: ${({$justifyContent}) => $justifyContent ? $justifyContent : "space-between"};
  margin: ${({margin}) => margin ? margin : 0};
  
  //border: 1px solid yellow;
`;
export const DateText = styled.div`
  font-size: 30px;
  font-weight: 400;
  color: ${theme.colors.whiteColor};
  margin-lefT: 20px;
`;
export const ListTitle = styled.div`
  height: 90px;
  font-size: 42px;
  font-weight: 600;
  color: ${theme.colors.whiteColor};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.deepBlueColor};
`;
