import React, { createRef, useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { FaPlay } from 'react-icons/fa';
import { PlayButton, PlayButtonBox, PlayInfoText, Video, Wrapper } from './video.style';
import { MainContext } from '../../pages/Main';

const VideoPlayer = ({
                         isMedia,
                         ratio,
                         videos = [],
                         videoRefs,
                         currentPlayIndex,
                         handleEnded
                     }) => {
    const { didData } = useContext(MainContext);
    const [volume, setVolume] = useState(localStorage.getItem('videoVolume') || 0.7);
    videoRefs.current = didData?.didAttached?.map((element, i) => videoRefs.current[i] ?? createRef());

    useEffect(() => {
        if (isMedia) { // 영상 재생
            const playPromise = videoRefs?.current?.[currentPlayIndex]?.current?.play();

            if (playPromise !== undefined) {
                playPromise.then(() => {
                    videoRefs?.current?.[currentPlayIndex]?.current?.play();
                }).catch(err => {
                    console.error(err);
                });
            }
        } else { // 영상 정지
            const playPromise = videoRefs?.current?.[currentPlayIndex]?.current?.pause();

            if (playPromise !== undefined) {
                playPromise.then(() => {
                    videoRefs?.current?.[currentPlayIndex]?.current?.pause();
                }).catch(err => {
                    console.error(err);
                });
            }
        }
    }, [videoRefs, isMedia, currentPlayIndex]);

    useEffect(() => {
        videoRefs.current?.forEach(video => { // 비디오 볼륨 설정
            video?.current?.addEventListener('volumechange', () => {
                if (video?.current?.muted) { // 음소거 일 경우
                    setVolume('0');
                    return;
                }
                setVolume(String(parseFloat(video?.current?.volume?.toFixed(1))));
            });
        });
    }, [videoRefs]);

    useEffect(() => {
        if (volume) {
            localStorage.setItem('videoVolume', String(volume));
        }
    }, [volume]);

    return (
        <Wrapper $flex={ratio}>
            {videos?.map((video, index) => {
                const fileLength = video?.da_url?.length;
                const lastDot = video?.da_url?.lastIndexOf('.');
                const fileExt = video?.da_url?.substring(lastDot + 1, fileLength)?.toLowerCase();

                if (index !== currentPlayIndex) { // 첫번째 비디오 외 영상 정지
                    const playPromise = videoRefs?.current?.[index]?.current?.pause();

                    if (playPromise !== undefined) {
                        playPromise.then(() => {
                            videoRefs?.current?.[index]?.current?.pause();
                        }).catch(err => {
                            console.error(err);
                        });
                    }
                }

                return (
                    <Video
                        key={`video-${index}`}
                        $visible={index === currentPlayIndex}
                        ref={videoRefs.current[index]}
                        autoPlay
                        type={`video/${fileExt}`}
                        onEnded={handleEnded}
                        loop={videos.length === 1}
                        playsInline
                        preload="auto"
                        controls
                        onLoadStart={el => el.target.volume = parseFloat(volume)}>
                        <source
                            key={`${index}-video`}
                            src={video?.da_url}
                            type={`video/${fileExt}`}
                        />
                    </Video>
                )
            })}
        </Wrapper>
    );
}

export default VideoPlayer;
