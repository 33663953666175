import React from "react";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from 'swiper/react';
import {Wrapper, ImageBox, Image} from "./image.style";
import noImage from '../../assets/images/noImage.png';

const ImagePlayer = ({ ratio, images }) => {
    return (
        <Wrapper $flex={ratio}>
            <Swiper
                spaceBetween={50}
                slidesPerView={1}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay]}
            >
                {images?.map((img, index) => (
                    <SwiperSlide key={`${index}-image`}>
                        <ImageBox>
                            <Image
                                src={img.da_url}
                                onError={e => e.target.src = noImage}
                            />
                        </ImageBox>
                    </SwiperSlide>
                ))}
            </Swiper>
        </Wrapper>
    )
}

export default ImagePlayer;
