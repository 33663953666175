import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { io } from 'socket.io-client';
import { Wrapper, FullScreen, BlackScreen, UserLogout, LogoutArea } from './main.style';
import Loader from '../../components/Loader';
import VideoPlayer from '../../components/VideoPlayer';
import ImagePlayer from '../../components/ImagePlayer';
import PatientInfo from '../../components/PatientInfo';
import { getSpeech } from '../../utils/commons';
import CallScreen from '../../components/CallScreen';
import BottomMessage from '../../components/BottomMessage';
import { useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { REQ_WAITING_PATIENT_LIST } from '../../graphql/query';
import { AuthContext } from '../../context/AuthContext';
import Marquee from '../../components/Marquee';

export const MainContext = createContext(null);
const email = localStorage.getItem('did_monitor_email');

const Main = () => {
    const { pathname } = useLocation();
    const { userLogout } = useContext(AuthContext);
    const [didData, setDidData] = useState(null); // 모니터 설정 정보
    const [socket, setSocket] = useState(null); // 소켓
    const [mediaRatio, setMediaRatio] = useState({ // 영상 비율 정보
        mediaRatio: 1,
        infoRatio: 1,
    });
    const [patientData, setPatientData] = useState(null); // 대기환자 정보
    const [callData, setCallData] = useState(null); // 호출자 정보
    const [callScreenVisible, setCallScreenVisible] = useState(false); // 호출자 모달
    const [isMedia, setIsMedia] = useState(true); // 영상, 이미지 화면 여부
    const [isPowerSaving, setIsPowerSaving] = useState(false); // 절전 모드
    const [currentPlayIndex, setCurrentPlayIndex] = useState(0); // video index
    const videoRefs = useRef([]); // 영상 DOM Ref

    const [reqWaitingPatientList] = useLazyQuery(REQ_WAITING_PATIENT_LIST, {
        variables: {
            hospitalEmail: email,
            didUniqueId: pathname?.replace('/', '')
        }
    });

    const handleEnded = useCallback(() => { // 영상 자동재생 Loop
        if (currentPlayIndex >= didData?.didAttached.length - 1) { //
            setCurrentPlayIndex(0);
            videoRefs?.current[0]?.current?.play();
            videoRefs?.current?.[didData?.didAttached.length - 1]?.current?.pause(); // 현재 재생중이던 영상 정지
        } else {
            setCurrentPlayIndex(prevIndex => prevIndex + 1);
            videoRefs?.current?.[currentPlayIndex]?.current?.pause(); // 현재 재생중이던 영상 정지
            videoRefs?.current?.[currentPlayIndex + 1]?.current?.play(); // 영상 재생
        }
    }, [didData, currentPlayIndex, videoRefs]);

    useEffect(() => {
        getSpeech(''); // 최초 1번 실행 (Window 에서 voices 가져오기 위함)
        const newSocket = io(process.env.REACT_APP_SOCKET_URL, {
            path: '/mediSocket',
            query: {
                hospitalEmail: email,
                didUniqueId: pathname?.replace('/', '')
            }
        });
        setSocket(newSocket);
    }, []);

    useEffect(() => {
        if (!socket) return;

        socket.emit('reqSeeDid', pathname?.replace('/', ''));
        socket.on('resSeeDid', response => {
            if (response !== 'null' && response) {
                const data = JSON.parse(response);
                setDidData(data);
            }
        });

        // 대기 환자 정보
        socket.on('getPatient', data => {
            if (data !== 'null' && data) {
                // console.info('getPatient : ', JSON.parse(data));
                setPatientData(JSON.parse(data));
            }
        });

        // 호출 환자 정보
        socket.on('callPatient', data => {
            if (data !== 'null' && data) {
                const patientInfo = JSON.parse(data);
                setCallData(patientInfo);
            }
        });

        // 모니터 설정 변경
        socket.on('updateDid', data => {
            if (data !== 'null' && data) {
                const did = JSON.parse(data)?.did;
                setDidData(did);
                window.location.reload();
            }
        });

        // 모니터 전체 절전 모드
        socket.on('allSaveDid', data => {
            if (data !== 'null' && data) {
                const { allDidSaveStatus } = JSON.parse(data);
                setIsPowerSaving(allDidSaveStatus);
            }
        });

        // 개별 모니터 절전 모드
        socket.on('saveDid', data => {
            if (data !== 'null' && data) {
                const { didSaveStatus } = JSON.parse(data);
                setIsPowerSaving(didSaveStatus);
            }
        });

        // 모니터 삭제
        socket.on('deleteDid', () => {
            localStorage.removeItem('did_monitor_token');
            localStorage.removeItem('did_monitor_email');
            window.location.href = '/';
        });

        return () => socket.close();
    }, [socket]);

    useEffect(() => {
        if (socket && didData) {
            reqWaitingPatientList().then((res) => {
                // console.info('reqWaitingPatientList :', res);
            }).catch(err => console.error(err));
        }
    }, [socket, didData]);

    useEffect(() => {
        if (callData) { // 호출 데이터
            const currentDoctorRoom = didData?.didDoctorRoom?.map(room => room.ddr_viewSelect && room);

            currentDoctorRoom.forEach(room => {
                if (didData?.did_calledVoiceUsed && room.ddr_doctorRoomName === callData.DeptName) { // 음성 호출 사용 && 진료실과 일치하는 호
                    // 영상 정지 후 음성 재생
                    const pausePromise = videoRefs?.current?.[currentPlayIndex]?.current?.pause();
                    if (pausePromise !== undefined) {
                        pausePromise.then(() => {
                            videoRefs?.current?.[currentPlayIndex]?.current?.pause();
                        }).catch(err => {
                            console.error(err);
                        });
                    }
                    // 음성 호출
                    getSpeech(callData.PatientName, callData.DeptName);
                }

                if (didData?.did_calledTextUsed && room.ddr_doctorRoomName === callData.DeptName) { // 호출 문구 사용 && 진료실과 일치하는 호출
                    setCallScreenVisible(true);
                }
            });

            setTimeout(() => { // 호출 종료 후 약 5초 후 영상 재생
                setCallScreenVisible(false);
                setCallData(null);

                if (videoRefs?.current?.[currentPlayIndex]?.current?.paused) {
                    const playPromise = videoRefs?.current?.[currentPlayIndex]?.current?.play();

                    if (playPromise !== undefined) {
                        playPromise.then(() => {
                            videoRefs?.current?.[currentPlayIndex]?.current?.play();
                        }).catch(err => {
                            console.error(err);
                        });
                    }
                }
            }, 5000);
        }
    }, [callData, didData, videoRefs, currentPlayIndex]);

    useEffect(() => {
        if (didData?.did_monitorRatio === '1 : 0') { // 환자 정보만 보기
            setIsMedia(false);
        }

        if (didData?.did_resUsed && didData?.did_monitorRatio !== '1 : 0' && didData?.did_transmitType === 'sometimes') {
            const { did_resInfoCycle, did_resInfoTime } = didData;

            if (!isMedia) { // 환자 정보 화면 상태
                setTimeout(() => {
                    setIsMedia(true); // 영상 or 이미지 재생 중
                }, did_resInfoTime * 1000);
            } else {
                setTimeout(() => {
                    setIsMedia(false); // 환자 정보 재생 중
                }, did_resInfoCycle * 1000);
            }
        }

        let infoRatio = 1; // 환자정보 비율
        let mediaRatio = 1; // 모니터 비율

        // 좌, 위 일 경우 비율 설정
        if (didData?.did_resInfoLocation === 'left' || didData?.did_resInfoLocation === 'up') {
            infoRatio = parseInt(didData?.did_monitorRatio?.split(' : ')?.[0], 10);
            mediaRatio = parseInt(didData?.did_monitorRatio?.split(' : ')?.[1], 10);
        } else {  // 우, 아래 일 경우 비율 설정
            infoRatio = parseInt(didData?.did_monitorRatio?.split(' : ')?.[1], 10);
            mediaRatio = parseInt(didData?.did_monitorRatio?.split(' : ')?.[0], 10);
        }

        setMediaRatio({
            infoRatio,
            mediaRatio
        });
    }, [didData, isMedia]);

    if (isPowerSaving) return <BlackScreen />;
    if (!email || !didData) return <Loader height='100vh' />;

    return (
        <MainContext.Provider value={{
            didData,
            patientData
        }}>
            <Wrapper
                $height={didData.did_lowMsgUsed ? 'calc(100vh - 84px)' : '100vh'}
                $down={didData?.did_resInfoLocation === 'down'}
                $left={didData?.did_resInfoLocation === 'left'}
                $right={didData?.did_resInfoLocation === 'right'}>
                {(didData?.did_resUsed && didData?.did_transmitType === 'always') && ( // 예약정보 사용 && 상시 노출
                    <PatientInfo
                        ratio={mediaRatio.infoRatio}
                    />
                )}
                {(didData?.did_resUsed && didData?.did_transmitType === 'sometimes') && ( // 예약정보 사용 && 일시 노출
                    <FullScreen $isMedia={isMedia}>
                        <PatientInfo
                            ratio={mediaRatio.infoRatio}
                        />
                    </FullScreen>
                )}
                {(didData.did_mediaType === 'video' && didData?.did_monitorRatio !== '1 : 0') && ( // 영상
                    <VideoPlayer
                        currentPlayIndex={currentPlayIndex}
                        isMedia={isMedia}
                        ratio={mediaRatio.mediaRatio}
                        videos={didData?.didAttached}
                        videoRefs={videoRefs}
                        handleEnded={handleEnded}
                    />
                )}
                {(didData.did_mediaType === 'image' && didData?.did_monitorRatio !== '1 : 0') && ( // 이미지
                    <ImagePlayer
                        ratio={mediaRatio.mediaRatio}
                        images={didData?.didAttached}
                    />
                )}

                {callScreenVisible && ( // 호출 화면
                    <CallScreen
                        callData={callData}
                    />
                )}
            </Wrapper>
            {didData.did_lowMsgUsed && ( // 하단 메시지
                <Marquee messageList={didData?.didLowMsg} />
            )}
            <LogoutArea>
                <UserLogout onClick={userLogout}>로그아웃</UserLogout>
            </LogoutArea>
        </MainContext.Provider>
    )
}

export default Main;
