import React, {useCallback, useContext, useState} from "react";
import {
    Wrapper,
    Image,
    LogoBox,
    LoginForm,
    LoginTitle,
    LoginSubTitle,
    LoginButton, LoaderBox, Loader
} from "./login.styles";
import StyledInput from "../../components/styled/StyledInput";
import StyledPasswordInput from "../../components/styled/StyledPasswordInput";
import logo from '../../assets/images/logo.png';
import {toast} from "react-toastify";
import {AuthContext} from "../../context/AuthContext";
import {isEmail} from "../../utils/commons";
import {useMutation} from "@apollo/client";
import {DID_LOGIN} from "../../graphql/mutation";
import {useParams} from "react-router-dom";

const Login = () => {
    const {userLogin} = useContext(AuthContext);
    const { serial } = useParams();
    const [inputs, setInputs] = useState({
        email: '',
        password: '',
    });
    const [loading, setLoading] = useState(false);
    const {email, password} = inputs;

    const [didLogin] = useMutation(DID_LOGIN)

    const onChangeInput = useCallback(e => {
        const {name, value} = e.target;

        setInputs({
            ...inputs,
            [name]: value
        });
    }, [inputs]);

    const handleSubmit = useCallback(async e => {
        e.preventDefault();

        if (email === '') {
            toast.error('이메일을 입력해주세요.');
            return;
        }
        if (password === '') {
            toast.error('비밀번호를 입력해주세요.');
            return;
        }
        if (!isEmail(email)) {
            toast.error('이메일 형식이 아닙니다.');
            return;
        }

        setLoading(true);

        try {
            const { data } = await didLogin({
                variables: {
                    accountId: email,
                    password: password,
                    didUniqueId: serial ? serial : '',
                }
            });

            if (data.didLogin.token) {
                userLogin(data.didLogin.token, data.didLogin.did_id, data.didLogin.email);
                setLoading(false);
            } else {
                toast.error('로그인에 실패했습니다.');
            }
        } catch (e) {
            toast.error(e.message);
        } finally {
            setLoading(false);
        }
    }, [email, password]);

    return (
        <Wrapper>
            <LogoBox>
                <Image src={logo}/>
            </LogoBox>
            <LoginForm onSubmit={handleSubmit}>
                <LoginTitle>로그인</LoginTitle>
                <LoginSubTitle>메디:플랫폼 회원 DID 모니터 로그인입니다.</LoginSubTitle>
                <StyledInput
                    width={340}
                    name='email'
                    value={email}
                    onChange={onChangeInput}
                    placeholder='이메일'
                />
                <StyledPasswordInput
                    width={340}
                    margin='12px 0 28px'
                    name='password'
                    value={password}
                    onChange={onChangeInput}
                    placeholder='비밀번호'
                />
                <LoginButton onClick={handleSubmit}>
                    {loading
                        ? (
                            <LoaderBox>
                                <Loader/>
                            </LoaderBox>
                        )
                        : '로그인'
                    }
                </LoginButton>
            </LoginForm>
        </Wrapper>
    )
}

export default Login;
