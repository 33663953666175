import styled, {keyframes} from "styled-components";
import theme from "../../styles/theme";

const blinkAnimation = keyframes`
  0% {
    opacity: 0;
  }
  3% {
    opacity: 1;
  }
  6% {
    opacity: 0;
  }
  9% {
    opacity: 1;
  }
  12% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  width: 86vw;
  max-width: 2190px;
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.85);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  border: 20px solid ${theme.colors.activeIndigo};
`;
export const Contents = styled.div`
  width: 100%;
  border: 20px solid ${theme.colors.activeBlue};
`;
export const GrayBox = styled.div`
  width: 100%;
  padding: 5vh 0;
  border: 20px solid ${theme.colors.listBorderColor};
`;
export const Text = styled.div`
  width: 100%;
  height: 100%;
  font-size: ${({ $fontSize }) => $fontSize}px;
  font-weight: 600;
  line-height: 2;
  text-align: center;
  white-space: pre-wrap;
  word-break: keep-all;
  padding: 30px 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.blackColor};
  animation: ${blinkAnimation} ease-out 10s;
`;
